import mitt from "mitt";
class EventBus {
  constructor() {
    this.emitter = mitt();
    this.openNotAllowedPage = "openNotAllowedPage";
    this.openWaitingPage = "openWaitingPage";
  }
}
const Bus = new EventBus();
export { Bus };
