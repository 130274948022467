export default [
  {
    logo: require("@/assets/images/integrations/mailchimp.webp"),
    name: "Mailchimp",
    description:
      "Partagez vos idées avec les bulletins d'information électroniques de Mailchimp, puis utilisez ses créateurs de pages de renvoi et de formulaires pour développer vos listes.",
    borderColor: "#ffe01b",
  },
  {
    logo: require("@/assets/images/integrations/sendinblue.webp"),
    name: "SendinBlue",
    description:
      "Sendinblue est le moteur du marketing par courriel avancé et de l'automatisation. Envoyez des lettres d'information et gérez les courriels de transaction.",
    borderColor: "#3498db",
  },
];
