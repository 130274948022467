import { errorMessage, successMessage } from "@/core/js/utils/msg";
import { get, getAllUrl, set } from "@/core/js/utils/utils";
import axios from "axios";

const SHOP_URL = "/app/keeper/api/shop";
const CATEGORY_URL = "/app/keeper/api/support/categories";
const LOGO_URL = "/app/keeper/api/network/logo";
const TAGS_URL = "/app/keeper/api/shop/tags";
const SHOP_NAME_URL = "/app/keeper/api/shop/name";

const SET_CATEGORIES = "SET_CATEGORIES";
const SET_SHOP = "SET_SHOP";

const shopStore = {
  namespaced: true,
  state: {
    shop: {},
    categories: JSON.parse(get("soy_keeper_categories") || null),
  },
  mutations: {
    [SET_SHOP](state, value) {
      state.shop = value;
    },
    [SET_CATEGORIES](state, value) {
      state.categories = value;
    },
  },
  actions: {
    fetchShop({ commit, rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(SHOP_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          commit(SET_SHOP, response.data);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    putShop({ rootState, dispatch }, shop) {
      return axios({
        method: "PUT",
        url: getAllUrl(SHOP_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: shop,
      })
        .then((response) => {
          dispatch("fetchShop");
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchCategories({ commit, rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(CATEGORY_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          set("soy_keeper_categories", JSON.stringify(response.data));
          commit(SET_CATEGORIES, response.data);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    uploadImage({ rootState }, image) {
      let data = new FormData();
      data.append("logo", image);

      return axios({
        method: "put",
        url: getAllUrl(LOGO_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
        timeout: 30000,
        data: data,
      })
        .then((resp) => {
          successMessage("Téléchargement du logo réussi !");
          return resp;
        })
        .catch((err) => {
          errorMessage(err.response.data.message);
          throw err;
        });
    },
    fetchTags({ rootState }) {
      return axios({
        method: "GET",
        url: getAllUrl(TAGS_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    putTags({ rootState }, tags) {
      return axios({
        url: getAllUrl(TAGS_URL),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
        data: tags,
      })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
    putShopName({ rootState }, name) {
      return axios({
        url: getAllUrl(SHOP_NAME_URL),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
        data: name,
      })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {
    shop: (state) => state.shop,
  },
};

export default shopStore;
