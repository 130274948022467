import { getAllUrl } from "@/core/js/utils/utils.js";
import axios from "axios";

const SET_SSID = "SET_SSID";
const SSID_URL = "/app/keeper/api/wifi/ssid";

const ssidStore = {
  namespaced: true,
  state: {
    ssidConfig: null,
  },
  mutations: {
    [SET_SSID](state, value) {
      state.ssidConfig = value;
    },
  },
  actions: {
    fetch({ rootState, commit }) {
      return axios({
        method: "GET",
        url: getAllUrl(SSID_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
      })
        .then((response) => {
          commit(SET_SSID, response.data);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    put({ rootState, dispatch }, params) {
      return axios({
        url: getAllUrl(SSID_URL),
        method: "put",
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: params.ssid,
      })
        .then((resp) => {
          dispatch("fetch");
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};

export default ssidStore;
