import { open } from "@/core/js/utils/utils.js";

const SHOW_ALERT = "SHOW_ALERT";
const CLOSE_ALERT = "CLOSE_ALERT";
const NEW_ALERT = "NEW_ALERT";
const SET_ALERT = "SET_ALERT";
const SET_ALERT_STATE = "SET_ALERT_STATE";

const msgStore = {
  namespaced: true,
  state: {
    alert: {
      type: "",
      message: "",
      active: false,
    },
  },
  mutations: {
    [SET_ALERT](state, value) {
      state.alert = value;
    },
    [SET_ALERT_STATE](state, value) {
      state.alert.active = value;
    },
  },
  actions: {
    [SHOW_ALERT]({ commit, state }) {
      commit(SET_ALERT_STATE, true);
      open(state.message, state.type);
    },
    [CLOSE_ALERT]({ commit }) {
      commit(SET_ALERT_STATE, false);
    },
    [NEW_ALERT]({ commit }, params) {
      commit(SET_ALERT, params);
      let icon = params.icon || "info";
      open(params.message, params.type, icon);
    },
  },
  getters: {
    alert: (state) => state.alert,
    isActive: (state) => !!state.alert.active,
  },
};

export default msgStore;
