import { interceptor } from "@/core/js/utils/interceptor";
import "@mdi/font/css/materialdesignicons.css";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import FontPicker from "font-picker-vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import Hotjar from "vue-hotjar";
import VueMixpanel from "vue-mixpanel";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import App from "./App.vue";
import titleMixin from "./mixins/titleMixin";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("font-picker", FontPicker);

Vue.use(Vuetify);
Vue.use(Buefy);
Vue.mixin(titleMixin);
Vue.use(Croppa);
Vue.use(VueCookies);
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: {
    debug: false,
  },
});
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_SITE_ID,
});

interceptor();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi",
    },
    theme: {
      themes: {
        light: {
          primary: "#6C63FF",
        },
        dark: {
          primary: "#6C63FF",
        },
      },
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
