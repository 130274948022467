import router from "@/router";
import store from "@/store";
import axios from "axios";
import Vue from "vue";

export function interceptor() {
  axios.defaults.timeout = 10000;
  return axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      const token = store.getters["userStore/token"];

      if (status) {
        if (status >= 500 && status < 600) {
          store.dispatch("userStore/logout").then(() => {
            router.push({ path: "login" });
          });
        } else if (
          status === 401 &&
          store.getters["userStore/pingging"] === false &&
          store.getters["userStore/isAuthenticated"]
        ) {
          sessionExpired(error);
        } else if (token && status >= 400 && status < 500) {
          apiDebugUi(token, error);
        }
      }
      return Promise.reject(error);
    }
  );
}

function apiDebugUi(token, error) {
  const seconds = Math.floor(Date.now() / 1000);

  const body = {
    api: "Keeper",
    debug_date: seconds,
    user_id: store.getters["userStore/user"]
      ? store.getters["userStore/user"].id
      : -1,
    http_code: error?.response?.status,
    message: error?.response?.data?.message || "",
    url: error?.response?.config?.url,
    method: error?.response?.config?.method,
    data: JSON.stringify(error?.response?.config?.data) || "",
  };
  axios({
    url: process.env.VUE_APP_API_URL + "/app/keeper/api/ui/debug",
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: body,
  }).catch((err) => {
    throw err;
  });
}

function sessionExpired(error) {
  if (
    Vue.$cookies.isKey("isloggedinSession") &&
    Vue.$cookies.isKey("isloggedinDuration")
  )
    store.dispatch("msgStore/NEW_ALERT", {
      message: error?.response?.data?.message,
      type: "is-danger",
      icon: "alert-circle",
      active: true,
    });
  store.dispatch("userStore/logout").then(() => {
    router.push({
      name: "login",
      query: { redirect: router.currentRoute.path },
    });
  });
}
