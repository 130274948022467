<template>
  <div class="linkWithPopup" @mouseleave="desactivePopup">
    <div
      v-if="link.popup"
      class="linkTitle"
      :class="isFocused(link) && 'isFocused'"
      @mouseover="activePopup"
    >
      <v-icon class="linkIcon" size="16">{{ link.icon }}</v-icon>
      <span>{{ link.label }}</span>
    </div>
    <router-link
      :to="link.to"
      v-else
      class="linkTitle basicLink"
      :class="isFocused(link) && 'isFocused'"
    >
      <v-icon class="linkIcon" size="16">{{ link.icon }}</v-icon>
      <span>{{ link.label }}</span>
    </router-link>
    <div
      v-if="link.popup"
      class="popupLink"
      :class="!isToggle && 'hidePopupLink'"
    >
      <div
        v-for="(item, id) in link.popup"
        :key="id"
        class="popupLinkItem"
        @click="onItemClick(item)"
      >
        <v-icon class="popupLinkIcon" :color="item.iconColor">
          {{ item.icon }}
        </v-icon>
        <div class="popupLinkDescription">
          <b>{{ item.label }}</b>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PopupLink",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    ...mapGetters("userStore", ["user"]),
  },
  methods: {
    activePopup() {
      this.isToggle = true;
    },
    desactivePopup() {
      this.isToggle = false;
    },
    onItemClick(item) {
      if (item.to) this.$router.push(item.to);
      else if (item.externalLink) window.open(item.externalLink, "_blank");
      else if (item.action) item.action(this.user.email);
      this.isToggle = false;
    },
    isFocused(link) {
      const currentRoute = this.$route.path;
      if (currentRoute === link.to) return true;
      else if (link.popup) {
        for (let i = 0; i < link.popup.length; i++)
          if (currentRoute === link.popup[i].to) return true;
      } else return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.linkWithPopup {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $slate;
  transition: all 0.3s ease;
  .linkTitle {
    cursor: pointer;
    margin-top: -10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .basicLink {
    color: $slate;
    &:hover {
      color: $primary !important;
    }
  }
  .popupLink {
    z-index: 60;
    position: absolute;
    top: 100%;
    left: 0;
    width: max-content;
    overflow: hidden;
    box-shadow: 0px 3px 8px rgba(99, 98, 100, 0.1);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(600px + 3rem);
    min-width: calc(300px + 2rem);
    gap: 1rem;
    transition: all 0.3s ease;
    .popupLinkItem {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      border-radius: 10px;
      padding: 1rem;
      cursor: pointer;
      .popupLinkIcon {
        margin: auto;
        margin-top: 0.1rem;
        opacity: 0.75;
      }
      .popupLinkDescription {
        margin-left: 1rem;
        color: $slate;
        b {
          line-height: 22px;
          font-size: 18px;
          font-weight: 600;
        }
        p {
          line-height: 1.6;
          margin-top: 5px;
          margin-bottom: 0 !important;
        }
      }
      &:hover {
        background-color: $bg-color;
        p,
        b {
          color: $primary !important;
        }
      }
    }
  }
  .hidePopupLink {
    transform: translateY(1rem);
    visibility: hidden;
    opacity: 0;
  }
  .isFocused {
    color: $primary !important;
    .linkIcon {
      color: $primary !important;
    }
  }
  .linkIcon {
    color: #2e3f50;
  }
  span {
    margin-left: 5px;
    font-size: 14px;
    line-height: 75px;
    font-weight: 500;
  }
  &:hover {
    color: $primary;
    .linkIcon {
      color: $primary;
    }
  }
  .isFocused {
    color: $primary !important;
    .linkIcon {
      color: $primary !important;
    }
  }
}
</style>
