import { open } from "./utils";

export function successMessage(txt) {
  open(txt, "is-success", "check-circle");
}

export function errorMessage(txt) {
  open(txt, "is-danger", "alert-circle");
}

export function warningMessage(txt) {
  open(txt, "is-warning");
}
