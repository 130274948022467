<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="scss">
@import "@/scss/global";

body {
  margin: 0;
}

#app {
  font-family: "Figtree", sans-serif;
  color: $slate;
  font-size: 14px;
}
</style>
