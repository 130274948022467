import { getAllUrl } from "@/core/js/utils/utils";
import axios from "axios";
const BILLING_URL = "/app/keeper/api/billing/update";
const IBAN_URL = "/app/keeper/api/support/payment/iban";
const PLAN_URL = "/app/keeper/api/support/billing/plans";
const INVOICE_LIST_URL = "/app/keeper/api/billing/invoices";

const billingStore = {
  namespaced: true,
  actions: {
    changeIBAN({ rootState }) {
      return axios({
        method: "GET",
        url: getAllUrl(IBAN_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchPlans({ rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(PLAN_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    putBilling({ rootState }, data) {
      return axios({
        method: "put",
        url: getAllUrl(BILLING_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: data,
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchInvoiceList({ rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(INVOICE_LIST_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};

export default billingStore;
