<template>
  <div class="soy-select" @mouseover="activeList" @mouseleave="desactiveList">
    <div
      ref="selectorToClick"
      class="selector"
      :style="{
        color: color,
        fontSize: fontSize + 'px',
        lineHeight: fontSize + 'px',
      }"
    >
      <span class="avatar-wrapper">{{ yellowUserLetter }}</span>
      <div v-if="toggleOnClick">
        <span class="selected" v-if="selected"> {{ toggleOnClickTitle }} </span>
      </div>
      <div v-else>
        <span class="selected" v-if="selected"> {{ selected.name }} </span>
      </div>
      <img class="soy-icon" src="@/assets/icons/custom-select-icon.svg" alt />
    </div>
    <div class="list" :class="!isToggle && 'hide'">
      <div
        v-for="item in data"
        :key="item.name"
        @click="selectItem(item)"
        class="list-item"
        :style="{
          color: color,
          fontSize: fontSize + 'px',
          lineHeight: fontSize + 'px',
          border: border,
        }"
      >
        <v-icon class="listItemIcon" small>{{ item.icon }}</v-icon>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "soySelect",
  props: {
    bus: {
      type: Object,
      required: false,
    },
    data: Array,
    selectedValue: {
      required: false,
    },
    toggleOnClick: {
      type: Boolean,
      default: false,
    },
    toggleOnClickTitle: {
      type: String,
      default: "Mon Compte",
    },
    color: {
      type: String,
      default: "#43425D",
    },
    fontSize: {
      type: String,
      default: "18",
    },
    border: {
      type: String,
      default: "none",
    },
    optionHeight: {
      type: String,
      default: "30",
    },
    minWidth: {
      type: String,
      default: "100",
    },
    name: {
      type: String,
      default: "none",
    },
  },
  data() {
    return {
      listHeight: 0,
      selected: null,
      isToggle: false,
    };
  },
  computed: {
    yellowUserLetter() {
      if (this.toggleOnClickTitle.length > 1)
        return this.toggleOnClickTitle.charAt(0);
      return "M";
    },
  },
  mounted() {
    const _func = (e) => {
      this.setClickoutListener(e);
    };
    document.removeEventListener("click", _func);
    document.addEventListener("click", _func);
    if (this.selectedValue) {
      this.selected = this.data.find(
        (item) => item.value === this.selectedValue
      );
    } else {
      this.selected = this.data[0];
    }
    if (this.bus) {
      this.bus.$on("toggleList", () => {
        this.toggleList();
      });
    }
  },
  methods: {
    activeList() {
      this.isToggle = true;
    },
    desactiveList() {
      this.isToggle = false;
    },
    selectItem(item = null) {
      this.$emit("selected", { name: this.name, value: item.value });
      this.listHeight = 0;
      this.selected = item;
      this.desactiveList();
    },
    triggerToggle() {
      this.listHeight = 200;
      this.$refs.selectorToClick.click();
    },
    setClickoutListener(event) {
      let currentElement = event.target;
      let i = 0;
      let result = false;
      while (currentElement !== null && i < 20) {
        if (currentElement.classList.contains("soy-select")) {
          result = true;
          break;
        }
        currentElement = currentElement.parentElement;
        i += 1;
      }
      if (result === false && this.listHeight > 0) {
        this.listHeight = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";
.soy-select {
  color: $slate;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  cursor: pointer;
  .selector {
    margin-top: -10px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    z-index: 30;
    .avatar-wrapper {
      display: inline-block;
      text-transform: uppercase;
      background: $slate;
      color: white;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      text-align: center;
      vertical-align: middle;
      position: relative;
      line-height: 33px;
      margin-right: 0.5rem;
    }
    .selected {
      text-transform: capitalize;
      text-align: center;
      color: $slate;
      font-weight: 500;
      font-size: 14px;
      @media (max-width: 857px) {
        display: none;
      }
    }
    .soy-icon {
      margin-left: 10px;
    }
  }
  .list {
    z-index: 60;
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    overflow: hidden;
    box-shadow: 0px 3px 8px rgba(99, 98, 100, 0.1);
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
    background-color: white;
    transition: all 0.3s ease;
    .list-item:last-child {
      border-top: 1px solid $grey-light !important;
    }
    .list-item {
      font-size: 14.5px;
      font-weight: 500;
      height: 30px;
      background-color: $white;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      padding-left: 1rem;
      padding-right: 3rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      text-transform: capitalize;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: $primary !important;
        .listItemIcon {
          color: $primary !important;
        }
      }
      .listItemIcon {
        margin-right: 0.5rem;
        color: $slate;
      }
    }
  }
  .hide {
    transform: translateY(1rem);
    visibility: hidden;
    opacity: 0;
  }
}
.select-user {
  .selector {
    justify-content: flex-end;
  }
}
</style>
