<template>
  <div class="menuBar">
    <v-btn small fab color="yellow">
      <v-icon large> mdi-plus </v-icon>
    </v-btn>
    <v-btn small fab color="#2e3f50" elevation="2" dark @click="changeOnglet">
      <v-icon> mdi-plus </v-icon>
    </v-btn>
    <v-btn small fab color="#2e3f50" elevation="2" dark @click="onScanClick">
      <v-icon small> mdi-credit-card-scan-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { errorMessage } from "@/core/js/utils/msg";

export default {
  name: "PlusButton",
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    changeOnglet() {
      errorMessage("Fonctionnalitée pas encore disponible !");
    },
    onScanClick() {
      this.$router.push("/scan-coupon");
    },
  },
};
</script>

<style scoped lang="scss">
.menuBar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  > :first-child {
    visibility: hidden;
  }
}
.item {
  font-weight: bold;
}
</style>
