<template>
  <div>
    <div class="wrapper" v-if="user">
      <div class="leftLinks">
        <div class="logo-wrapper">
          <router-link to="/base/client">
            <img src="@/assets/images/soyclub-logo.svg" alt="soy_logo" />
          </router-link>
        </div>
        <div class="links">
          <PopupLink
            v-for="(link, index) in navbarLinks"
            :key="index"
            :link="link"
          />
        </div>
      </div>
      <div class="user-info" v-if="bus">
        <soy-select
          v-if="bus"
          minWidth="150"
          optionHeight="38"
          font-size="15"
          :data="userSelectItems"
          class="select-user"
          :toggleOnClick="true"
          :toggleOnClickTitle="fullName"
          ref="soySelectMenu"
          :bus="bus"
          @selected="handleUserSelect"
        >
        </soy-select>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import SoySelect from "@/core/components/soy-select.vue";
import PopupLink from "@/core/components/PopupLink.vue";

import navbarItems from "@/shared/navbarItems.js";

export default {
  name: "SecondaryMenu",
  components: {
    SoySelect,
    PopupLink,
  },
  data() {
    return {
      bus: new Vue(),
      selectedItem: "flow",
      userSelectItems: [
        {
          name: "Mon compte",
          icon: "mdi-account-outline",
          value: 1,
        },
        {
          name: "Paramètres",
          icon: "mdi-cog-outline",
          value: 2,
        },
        {
          name: "Factures",
          icon: "mdi-cash-multiple",
          value: 3,
        },
        {
          name: "Déconnexion",
          icon: "mdi-logout",
          value: 4,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("userStore", ["user"]),
    yellowUserLetter() {
      return this.user && this.user.firstname ? this.user.firstname[0] : null;
    },
    fullName() {
      return this.user
        ? `${this.user.firstname} ${this.user.lastname}`
        : "Mon Compte";
    },
    navbarLinks() {
      return navbarItems;
    },
  },
  methods: {
    toggleList() {
      this.$refs.soySelectMenu.triggerToggle();
    },
    handleUserSelect(event) {
      switch (event.value) {
        case 2:
          this.goto({ name: "wifi-settings" });
          break;
        case 3:
          this.goto({ name: "billing-overview" });
          break;
        case 4:
          this.goto({ name: "logout" });
          break;
        default:
          this.goto({ name: "profile" });
          break;
      }
    },
    goto(path) {
      if (this.$router.currentRoute.name !== path.name) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.wrapper {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background: $white;
  width: 100%;
  height: 75px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0 0 15px 0 rgba(92, 50, 208, 0.1);
  .leftLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      margin-left: 1rem;
      img {
        vertical-align: middle;
        height: 1.65rem;
      }
    }
    .links {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .link {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $grey2;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        .linkTitle {
          margin-top: -10px;
        }
        .linkIcon {
          color: #2e3f50;
        }
        span {
          margin-left: 5px;
          font-size: 14px;
          line-height: 75px;
          font-weight: 500;
        }
        &:hover {
          color: $purple;
          .linkIcon {
            color: $purple;
          }
        }
      }
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
