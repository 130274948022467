var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"soy-select",on:{"mouseover":_vm.activeList,"mouseleave":_vm.desactiveList}},[_c('div',{ref:"selectorToClick",staticClass:"selector",style:({
      color: _vm.color,
      fontSize: _vm.fontSize + 'px',
      lineHeight: _vm.fontSize + 'px',
    })},[_c('span',{staticClass:"avatar-wrapper"},[_vm._v(_vm._s(_vm.yellowUserLetter))]),(_vm.toggleOnClick)?_c('div',[(_vm.selected)?_c('span',{staticClass:"selected"},[_vm._v(" "+_vm._s(_vm.toggleOnClickTitle)+" ")]):_vm._e()]):_c('div',[(_vm.selected)?_c('span',{staticClass:"selected"},[_vm._v(" "+_vm._s(_vm.selected.name)+" ")]):_vm._e()]),_c('img',{staticClass:"soy-icon",attrs:{"src":require("@/assets/icons/custom-select-icon.svg"),"alt":""}})]),_c('div',{staticClass:"list",class:!_vm.isToggle && 'hide'},_vm._l((_vm.data),function(item){return _c('div',{key:item.name,staticClass:"list-item",style:({
        color: _vm.color,
        fontSize: _vm.fontSize + 'px',
        lineHeight: _vm.fontSize + 'px',
        border: _vm.border,
      }),on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-icon',{staticClass:"listItemIcon",attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }