export default {
  menu: [
    {
      name: "settings",
      value: [
        {
          name: "profile",
          title: "Mon Profil",
          path: "/settings/profile",
          icon: {
            name: "myprofile",
            value: "account_box",
            height: 27,
            width: 40,
          },
        },
        {
          name: "shop",
          title: "Mon Shop",
          path: "/settings/shop",
          icon: {
            name: "myshop",
            value: "store",
            height: 36,
            width: 36,
          },
        },
        {
          name: "security",
          title: "Sécurité",
          path: "/settings/security",
          icon: {
            name: "security",
            value: "https",
            height: 36,
            width: 36,
          },
        },
        {
          name: "notifications",
          title: "Notifications",
          path: "/settings/notifications",
          icon: {
            name: "notifications",
            value: "notifications_active",
            height: 34,
            width: 36,
          },
        },
        {
          name: "billing",
          title: "Facturation",
          path: "/settings/billing",
          icon: {
            name: "billing",
            value: "credit_card",
            height: 30,
            width: 36,
          },
        },
      ],
    },
    {
      name: "flow",
      value: [
        {
          name: "news",
          title: "Fil d'actualité",
          path: "/flow",
          icon: {
            name: "news",
            value: "dynamic_feed",
            height: 27,
            width: 36,
          },
        },
        // {
        //   name: "analytics",
        //   title: "Analyses",
        //   path: "/flow",
        //   icon: {
        //     name: "analytics",
        //     value: "bar_chart",
        //     height: 36,
        //     width: 36,
        //   },
        // },
        {
          name: "parameters",
          title: "Paramètres",
          path: "/flow",
          icon: {
            name: "parameters",
            value: "settings",
            height: 30,
            width: 36,
          },
        },
      ],
    },
    {
      name: "base",
      value: [
        {
          name: "client",
          title: "Clients",
          path: "/base/client",
          icon: {
            name: "client",
            value: "contact_mail",
            height: 27,
            width: 36,
          },
        },
        // {
        //   name: "analytics-base",
        //   title: "Analyses",
        //   path: "/base/analytics-base",
        //   icon: {
        //     name: "analytics-base",
        //     value: "bar_chart",
        //     height: 30,
        //     width: 36,
        //   },
        // },
        {
          name: "campaigns",
          title: "Campagnes",
          path: "/base/campaigns",
          icon: {
            name: "campaigns",
            value: "send",
            height: 30,
            width: 36,
          },
        },
      ],
    },
    {
      name: "connect",
      value: [
        {
          name: "monitor",
          title: "Moniteur",
          path: "/connect/monitor",
          icon: {
            name: "monitor",
            value: "network_check",
            height: 27,
            width: 36,
          },
        },
        {
          name: "settings",
          title: "Paramètres",
          path: "/connect/settings",
          icon: {
            name: "settings",
            value: "settings",
            height: 30,
            width: 36,
          },
        },
      ],
    },
  ],
};
