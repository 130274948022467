<template>
  <b-modal
    :active="showWaitingPage"
    width="720px"
    can-cancel
    :on-cancel="closeModal"
    class="modal is-active"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <h3>Bientôt disponible 😍</h3>
      <div class="modal-gif">
        <img
          class="rounded"
          src="@/assets/images/coucou_anim.gif"
          alt="A man making a hi sign with his hand"
        />
      </div>
      <p class="modal-message">
        Notre équipe est en train de mettre en place cette fonctionnalité Stay
        Tuned !
      </p>
      <div class="modal-social">
        <span>Suivez-nous sur:</span>
        <div class="social-networks">
          <a
            href="https://facebook.com/wifi.shoponyou/"
            target="_blank"
            rel="noopener"
          >
            <img src="@/assets/images/facebook.svg" alt="" />
          </a>
          <a
            href="https://www.instagram.com/wifi.shoponyou/"
            target="_blank"
            rel="noopener"
          >
            <img src="@/assets/images/instagram.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="modal-action">
        <button class="button is-purple" @click="closeModal">
          J'ai compris
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Bus } from "@/core/js/EventBus";

export default {
  name: "WaitingPage",
  data() {
    return {
      showWaitingPage: false,
    };
  },
  computed: {
    show() {
      return this.showWaitingPage || false;
    },
  },
  mounted() {
    Bus.emitter.on(Bus.openWaitingPage, () => (this.showWaitingPage = true));
  },
  methods: {
    closeModal() {
      this.showWaitingPage = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/elements/notification.sass";
@import "bulma/sass/components/card.sass";
@import "bulma/sass/components/modal.sass";
@import "@/scss/global";

.modal {
  z-index: 100;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 720px;
  height: 684px;
  @include md {
    width: 620px;
    height: 684px;
  }
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 2.2rem 4.1rem;

  h3 {
    text-align: center;
    font: bold 26px/40px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #6b34ad;
  }
}

.modal-gif {
  margin-top: 2.63rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
}

.modal-message {
  margin-top: 2.63rem;
  text-align: center;
  font: bold 18px/29px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
}

.modal-social {
  margin-top: 1.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .span {
    text-align: center;
    font: Regular 14px/16px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #2d2d2d;
  }

  .social-networks {
    margin-top: 0.63rem;
    img {
      margin: 0 0.5rem;
      width: 36px;
      height: 36px;
    }
  }
}

.modal-action {
  margin-top: 2.2rem;
  button {
    text-align: center;
    font: normal 18px/24px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    background: $purple 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 224px;
    height: 50px;
  }
}
</style>
