export default {
  apiBaseUrl: process.env.VUE_APP_API_URL,
  regex: {
    email: /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z]{2,3})$/,
    password: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{7,}$/,
    wpa: /^[\x20-\x7E]{8,}$/,
    formatAllTel:
      /^(([+][3]{2})(?!0)[0-9]{9})$|^([0]{1})[0-9]{9}$|^[+](?!33)[0-9]{0,4}[0-9]{9}$/,
    formatTelFrInternational: /^([+][3]{2})[0-9]{9}$/,
    formatTelFr: /^([0]{1})[0-9]{9}$/,
    formatTelEuInternational: /^[+][0-9]{0,4}[0-9]{9}$/,
  },
  allowedRoutes: ["password2", "password1"],
  autolog: false,
};
