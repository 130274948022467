<template>
  <div class="login-container">
    <div class="login-form-wrapper">
      <div class="form-wrapper login-form">
        <div class="logo">
          <img
            src="@/assets/images/soyclub-logo.svg"
            width="200px"
            alt="shop on you"
          />
        </div>
        <router-view />
      </div>
      <div id="cgu-link">
        <a
          href="https://www.shoponyou.com/conditions"
          target="_blank"
          class="soy-link"
        >
          Conditions générales d'utilisation
        </a>
      </div>
    </div>
    <div class="insta">
      <div class="insta-wrapper">
        <span class="insta-title">PASSEZ SUR INSTAGRAM</span>
        <p class="insta-subtitle">
          Suivez notre page et contactez-nous, on met en avant vos promos 😍
        </p>
        <a
          class="insta-btn"
          href="https://www.instagram.com/soyclubapp"
          alt="Lien du compte Instagram"
          target="_blank"
          rel="noopener"
        >
          Suivre Instagram
        </a>
        <img
          id="stripes"
          src="@/assets/images/black-stripes.svg"
          alt="Black Stripes"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  title: "Log In",
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.login-container {
  background: $bg-color;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-column: 1/2;
    position: relative;
    .logo {
      align-self: flex-start;
      margin-top: 3rem;
      margin-bottom: auto;
    }

    .login-form {
      width: 100%;
      max-width: 400px;
      height: 100%;
    }
    #cgu-link {
      font-size: 12px;
      margin-bottom: 2rem;
    }
    @include lg {
      grid-column: 1/3;
      padding: 30px;
    }
  }
  .insta {
    background-color: $yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .insta-wrapper {
      max-width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .insta-title {
        color: $slate;
        font-weight: 800;
        font-size: 50px;
        line-height: 53px;
      }
      .insta-subtitle {
        color: $slate;
        font-size: 16px;
        line-height: 22px;
        font-weight: bolder;
      }
      .insta-btn {
        width: 270px;
        margin-top: 30px;
        text-decoration: none;
        border: 1px solid $slate;
        border-radius: 5px;
        padding: 1rem;
        color: $slate;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        transition: all 0.3s ease;
        &:hover {
          background-color: rgba($color: #7c7c7c, $alpha: 0.1);
        }
      }
      #stripes {
        position: absolute;
        left: -240px;
        top: 10px;
      }
    }
    @include lg {
      display: none;
    }
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .logo {
    margin-bottom: 10px;
  }
  .subtitle {
    color: $grey;
    text-align: center;
    opacity: 0.5;
    margin-bottom: 40px;
    margin-top: 0;
  }
  .soy-primary-btn {
    margin-top: 25px;
  }
  .links-container {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

.soy-link {
  color: $slate !important;
  text-decoration: none;
  &:hover {
    color: $primary !important;
    text-decoration: underline;
  }
}

@include lg {
  .form-wrapper {
    .soy-primary-btn {
      max-width: 270px;
      margin: 0 auto 0 auto;
    }

    .soy-link {
      width: 100%;
      text-align: center;
      &:first-child {
        margin-top: 30px;
      }
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}

@include md {
  .form-wrapper {
    .subtitle {
      color: $grey;
      text-align: center;
      opacity: 0.5;
      margin-bottom: 15px;
      margin-top: 0;
    }
    .links-container {
      margin-top: 0;
      .soy-link {
        &:first-child {
          margin-top: 15px;
        }
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
