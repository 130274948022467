<template>
  <div v-if="this.showNotAllowedPage" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <h3>Passez à un plan supérieur 🆙</h3>
      <div class="modal-gif">
        <img
          class="rounded"
          src="@/assets/images/coucou_anim.gif"
          alt="A man making a hi sign with his hand"
        />
      </div>
      <p class="modal-message">
        Pour tester cette super-fonctionnalité, passez simplement au forfait
        WiFilytics, c’est sans engagement !
      </p>
      <div class="modal-action">
        <div class="columns">
          <div class="column">
            <button
              class="button is-outlined is-purple soy-secondary-btn"
              @click="closeModal"
            >
              Annuler
            </button>
          </div>
          <div class="column">
            <button class="button is-purple soy-primary-btn" @click="goToPlan">
              Souscrire
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bus } from "@/core/js/EventBus";
import { mapGetters } from "vuex";
import { warningMessage } from "@/core/js/utils/msg.js";
import { ifElseCondition } from "@/core/js/utils/utils.js";

export default {
  name: "NotAllowedPage",
  data() {
    return {
      showNotAllowedPage: false,
    };
  },
  computed: {
    ...mapGetters("userStore", ["user"]),
  },
  mounted() {
    Bus.emitter.on(
      Bus.showNotAllowedPage,
      () => (this.showNotAllowedPage = true)
    );
  },
  methods: {
    /**
     * Redirection method.
     * @return a push to the subscription path and close the modal after it.
     */
    goToPlan() {
      let user = JSON.parse(JSON.stringify(this.user));

      ifElseCondition(
        !user.subscription_allowed,
        () => {
          warningMessage(
            "Vous êtes actuellement sur un plan spécifique, contactez le support pour changer de plan."
          );
          this.closeModal();
        },
        () => {
          this.$router.push({ name: "plan" });
          this.closeModal();
        }
      );
    },
    /**
     * Close the modal.
     * @return a request to set the modal to false.
     */
    closeModal() {
      this.showNotAllowedPage = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/elements/notification.sass";
@import "bulma/sass/components/card.sass";
@import "bulma/sass/components/modal.sass";
@import "@/scss/global";

.modal {
  z-index: 100;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 720px;
  height: 684px;
  @include md {
    width: 620px;
    height: 684px;
  }
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 4px;
  opacity: 1;
  padding: 2.2rem 4.1rem;

  h3 {
    text-align: center;
    font: bold 26px/40px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #6b34ad;
  }
}

.modal-gif {
  margin-top: 2.63rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
}

.modal-message {
  margin-top: 2.63rem;
  text-align: center;
  font: bold 18px/29px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
}

.modal-social {
  margin-top: 1.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .span {
    text-align: center;
    font: Regular 14px/16px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #2d2d2d;
  }

  .social-networks {
    margin-top: 0.63rem;
    img {
      margin: 0 0.5rem;
      width: 36px;
      height: 36px;
    }
  }
}

.modal-action {
  margin-top: 2.2rem;
  button {
    text-align: center;
    font: normal 18px/24px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    background: $purple 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 224px;
    height: 50px;
  }
}

.button.is-purple.is-outlined.is-focused,
.button.is-purple.is-outlined.is-hovered,
.button.is-purple.is-outlined:hover {
  background-color: $purple;
  border-color: $purple;
  color: #fff;
}

.button.is-purple.is-outlined {
  background-color: transparent;
  border-color: $purple;
  color: $purple;
}

.button.is-purple.is-hovered,
.button.is-purple:hover {
  background-color: $purple-dark;
  border-color: transparent;
  color: #fff;
}

.button.is-purple {
  background-color: $purple;
  border-color: transparent;
  color: #fff;
  word-wrap: break-word;
}
</style>
